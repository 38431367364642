import { FormioAppConfig } from 'angular-formio';
import { FormioAuthConfig } from 'angular-formio/auth';

// prod server
export const AppConfigProd: FormioAppConfig = {
    apiUrl: 'https://form.command-app.com',
    appUrl: 'https://form.command-app.com',
    icons: 'fontawesome'
};

// amazon
export const AppConfigAmazon: FormioAppConfig = {
    appUrl: 'http://formdev.command-app.com:3001',
    apiUrl: 'http://formdev.command-app.com:3001',
    icons: 'fontawesome'
};

// bigdata.si
export const AppConfigBigdata: FormioAppConfig = {
    appUrl: 'http://localhost:3001',
    apiUrl: 'http://localhost:3001',
    icons: 'fontawesome'
};


export function GetFormioConfig(): FormioAppConfig {

    if (window.location.hostname.indexOf('member.command-app.com') != -1) {
        return AppConfigProd;
    }
    if (window.location.hostname.indexOf('admindev.reactorcommand.com') != -1) {
        return AppConfigAmazon;
    }
    else if (window.location.hostname.indexOf('adminsup.reactorcommand.com') != -1) {
        return AppConfigAmazon;
    }
    else if (window.location.hostname.indexOf('bigdata.si') != -1) {
        return AppConfigBigdata;
    }
    else if (window.location.hostname.indexOf('localhost') != -1 ||
            window.location.hostname.indexOf('127.0.0.1') != -1) {
        return AppConfigBigdata;
    }

    return AppConfigAmazon;
}

